// Main JS Here

//  enclose jQuery
(function( $ ) {

    // document ready
    $(function(){

        // hacky touch device check
        function is_touch_enabled() { 
            return ( 'ontouchstart' in window ) ||  
                   ( navigator.maxTouchPoints > 0 ) ||  
                   ( navigator.msMaxTouchPoints > 0 ); 
        }
        if ( is_touch_enabled() ) { 
            $('body').addClass('is_touch');
        } else {
            $('body').removeClass('is_touch');
        }

        // Open / Close Global Nav Panel
        var $navBarWrapper = $('.navbar-wrapper');
        var $navToggles = $('.navbar-toggler, .navbar-closer');

        $navToggles.on('click', function(){
            $navBarWrapper.fadeToggle('slow');
            $('.logo-main').toggleClass('nav-open');
            $('html, body').toggleClass('modal-open');
        });

        // Hover States for Project Listing Images
        $('.project-item').hover( function() {
            $(this).find('.project-item-label').toggleClass('active');
        });

        // Hide / Show property listings on Homepage
        $('.load-list').each(function( index, el ) {
            $(el).find('li:lt(3)').slideDown('fast');
        });
        $('p.load-more a').on('click', function(e) {
            e.preventDefault();
            var data_list = $(this).data('list');
            $('.' + data_list  + ' li').not(':visible').slice(0, 3).show();
            if ( $('.' + data_list + ' li').not(':visible').length < 1 ) {
                $(this).hide();
            }
        });

        // Open filter menu on click
        $('.custom-select-wrapper').on('click', function() {
            $('.custom-select').toggleClass('open');
        });

        // Prevent Image Drag in Photo Gallery
        $('.project-gallery-wrapper img').mousedown(function(e){
            e.preventDefault()
        });

        // Swap display value and trigger filtering on filter menu change
        $('.custom-option').on('click', function() {

            if ( ! $(this).hasClass('selected') ) {

                // get menu type
                var menu_type = $(this).closest( '.custom-select-wrapper' ).data('menu');
                // console.log( menu_type );
                if ( menu_type === 'projects' ) {
                    $filter_els = $('.project_item');
                } else if ( menu_type == 'data' ) {
                    filter_els = '.data-city-wrap';
                }

                // get selected option info
                $(this).addClass('selected').siblings('.custom-option.selected').removeClass('selected');
                var city_slug = $(this).data('value');
                var city_name = $(this).text();

                // update selected display
                $('.custom-select__trigger span').text( city_name );

                // do filtering
                if ( '' !== city_slug && 'all' !== city_slug ) {

                    if ( menu_type === 'projects' ) {
                        $('.project-item[data-city=' + city_slug + ']').fadeIn(500);
                        $('.project-item[data-city!=' + city_slug + ']').fadeOut(500);
                    } else if ( menu_type == 'data' ) {
                        $('.data-city-wrap[data-city=' + city_slug + ']').fadeIn(500);
                        $('.data-city-wrap[data-city!=' + city_slug + ']').fadeOut(500);
                    }

                } else if ( 'all' == city_slug ) {

                    if ( menu_type === 'projects' ) {
                        $('.project-item').fadeIn(500);
                    } else if ( menu_type == 'data' ) {
                        $('.data-city-wrap').fadeIn(500);
                    }
                }
            }
        });

        // Close filter menu if open and click is outside of element
        $(document).click(function(event) {
            $target = $(event.target);
            if ( !$target.closest('.custom-select').length && $('.custom-select').hasClass('open') ) {
                $('.custom-select').removeClass('open')
            }
        });

        $('.select-menu li').click(function() {
            $(this).siblings().addBack().children().remove();
            var a = $(this).siblings().toggle();
            //$(this).siblings().append('<img src="https://cdn4.iconfinder.com/data/icons/6x16-free-application-icons/16/Delete.png" style="float:right; width:12px; height:12px;">');
            $(this).parent().prepend(this);
        });

        var $projectGallery = $('.project-gallery');
        var $projectGalleryWrapper = $('.project-gallery-wrapper');
        var galleryOpenDuration = 300;

        function initializeGallery() {
            if($projectGallery.length > 0 && $projectGallery.hasClass('slick-initialized') === false) {
                $projectGallery.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                    dots: false,
                    infinite: true,
                    adaptiveHeight: false,
                    arrows: true,
                    lazyLoad: 'ondemand',
                }).on('beforeChange', stopAndHideVideo);
            }
        }

        function stopAndHideVideo(){
            var $currentSlide = $('.slick-current');
            var $iFrame = $currentSlide.find('iframe');

            if ($iFrame.length) {
                $currentSlide.removeClass('is-playing-video');
                $iFrame.attr('src', '');
            }
        }

        function openGallery() {
            $projectGalleryWrapper.fadeIn(galleryOpenDuration, initializeGallery);
        }

        function closeGallery() {
            $projectGalleryWrapper.fadeOut(galleryOpenDuration);
        }


        $(document).bind('contextmenu', function(e) {

            if ( $('body.single-project.modal-open').length ) {
                console.log( 'gallery modal open' );
                return false;
            } else {
                console.log( 'gallery modal closed' );
                return true;
            }
        });

        // Open / Close gallery
        $('#gallery-link a, .project-detail-image.has-gallery').on('click', function(e) {
            e.preventDefault();
            openGallery();
            $('html, body').addClass('modal-open');
        });

        // Open / Close gallery
        $('.modal-closer').on('click', function(e) {
            e.preventDefault();
            stopAndHideVideo();
            closeGallery();
            $('html, body').removeClass('modal-open');
        });

        // Play videos in Gallery
        $('.video-trigger .initial-slide').on('click', function() {
            var $currentSlide = $('.slick-current');
            var videoSrc = $currentSlide.find('.slide-video').data('video');
            var $iFrame = $currentSlide.find('iframe');

            $currentSlide.addClass('is-playing-video');
            $iFrame.attr('src', videoSrc );
        });


        // Force Safari reload when going back to Showcase listing page
        window.addEventListener('pageshow', function(event) {
            if ( window.location.href.match('/showcase') && event.persisted ) {
                this.console.log( 'event persisted')
                $('.project-item-label').removeClass('active');
            }
        });


        // Home Reel Video Play
        var autoplayVideo = function (modal) {

            if(!modal) {
                return;
            }

            // Look for a YouTube video in the modal
            var video = modal.querySelector('iframe[src*="www.youtube.com"]');

            // Bail if the modal doesn't have a video
            if (!video) return;

            // Add autoplay to video src
            video.src = video.src + (video.src.indexOf('?') < 0 ? '?' : '&') + 'autoplay=1';

            $('.video').fitVids();
        };

        // Home Reel Video Stop
        var stopVideo = function (modal) {

            if(!modal) {
                return;
            }

            // Look for a YouTube video in the modal
            var video = modal.querySelector('iframe[src*="www.youtube.com"]');

            // Bail if the modal doesn't have a video
            if (!video) return;

            // Remove autoplay from video src
            video.src = video.src.replace('&autoplay=1', '').replace('?autoplay=1', '');

        };

        // Init Home Reel Modal script, setup callback
        modals.init({
            callbackOpen: function ( toggle, modal ) {
                autoplayVideo(modal);
                $('.player .video').fitVids();
            },
            callbackClose: function ( toggle, modal ) {
                stopVideo(modal);
            }
        });

        // Make Data boxes clickable
        var setClickBoxes = function() {
            if ( $('.click-box').length ) {
                $('.click-box').on('click', function(e) {
                    var link = $(this).find('a');
                    e.preventDefault();
                    e.stopPropagation();
                    if ( link.length ) {
                        if ( '_blank' == link.attr('target') ) {
                            window.open( link.attr('href') );
                        } else {
                        window.location = link.attr('href');
                        }
                    }
                });
            }
        };
        setClickBoxes();



    });
    // end onload scripts
})(jQuery);


